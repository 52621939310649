var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-snackbar',{attrs:{"color":_vm.snackbar_color,"rounded":"pill","top":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","icon":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-window-close")])],1)]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.allAdminProperty,"sort-by":"calories"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("All Properties")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer')],1),(_vm.loading)?_c('v-progress-linear',{attrs:{"active":_vm.loading,"indeterminate":_vm.loading,"color":"primary","top":""}}):_vm._e()]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"info","icon":"","to":{
              name: 'adminProperty',
              params: { id: item.basic_property_id },
            }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',{staticClass:"caption"},[_vm._v("View Details")])]),(item.property_status_id == 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","icon":""},on:{"click":function($event){return _vm.upadatePropertyStatus(
                item.property_status_id,
                item.basic_property_id
              )}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-check-circle ")])],1)]}}],null,true)},[_c('span',{staticClass:"caption"},[_vm._v("Approve Property")])]):_vm._e(),(item.property_status_id == 2)?_c('v-tooltip',{attrs:{"bottom":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"red","icon":""},on:{"click":function($event){return _vm.upadatePropertyStatus(
                item.property_status_id,
                item.basic_property_id
              )}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-close-circle ")])],1)]}}],null,true)},[_c('span',{staticClass:"caption"},[_vm._v("Reject Property")])]):_vm._e(),(item.favourite_status == 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","icon":""},on:{"click":function($event){return _vm.upadateFavouriteStatus(
                item.favourite_status,
                item.basic_property_id
              )}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-heart-circle-outline ")])],1)]}}],null,true)},[_c('span',{staticClass:"caption"},[_vm._v("Add to favourite")])]):_vm._e(),(item.favourite_status == 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.upadateFavouriteStatus(
                item.favourite_status,
                item.basic_property_id
              )}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-heart-circle ")])],1)]}}],null,true)},[_c('span',{staticClass:"caption"},[_vm._v("Added to favourite")])]):_vm._e()]}},{key:"item.image",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"pa-2"},[_c('v-img',{staticClass:"rounded",attrs:{"src":_vm.imgURL +
            '/' +
            item.user_id +
            '/' +
            item.basic_property_id +
            '/' +
            item.image_name,"alt":item.image_name,"height":"64px","width":"64px"}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }