<template>
  <div>
    <v-snackbar v-model="snackbar" :color="snackbar_color" rounded="pill" top>
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-data-table
      :headers="headers"
      :items="allAdminProperty"
      sort-by="calories"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>All Properties</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          color="primary"
          v-if="loading"
          top
        />
      </template>

      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="info"
              icon
              v-bind="attrs"
              v-on="on"
              :to="{
                name: 'adminProperty',
                params: { id: item.basic_property_id },
              }"
            >
              <v-icon> mdi-eye </v-icon>
            </v-btn>
          </template>
          <span class="caption">View Details</span>
        </v-tooltip>

        <v-tooltip bottom v-if="item.property_status_id == 1">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="success"
              icon
              v-bind="attrs"
              v-on="on"
              @click="
                upadatePropertyStatus(
                  item.property_status_id,
                  item.basic_property_id
                )
              "
            >
              <v-icon> mdi-check-circle </v-icon>
            </v-btn>
          </template>
          <span class="caption">Approve Property</span>
        </v-tooltip>

        <v-tooltip bottom dark v-if="item.property_status_id == 2">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="red"
              icon
              v-bind="attrs"
              v-on="on"
              @click="
                upadatePropertyStatus(
                  item.property_status_id,
                  item.basic_property_id
                )
              "
            >
              <v-icon> mdi-close-circle </v-icon>
            </v-btn>
          </template>
          <span class="caption">Reject Property</span>
        </v-tooltip>

        <v-tooltip bottom v-if="item.favourite_status == 0">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="success"
              icon
              v-bind="attrs"
              v-on="on"
              @click="
                upadateFavouriteStatus(
                  item.favourite_status,
                  item.basic_property_id
                )
              "
            >
              <v-icon> mdi-heart-circle-outline </v-icon>
            </v-btn>
          </template>
          <span class="caption">Add to favourite</span>
        </v-tooltip>
        <v-tooltip bottom v-if="item.favourite_status == 1">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="error"
              icon
              v-bind="attrs"
              v-on="on"
              @click="
                upadateFavouriteStatus(
                  item.favourite_status,
                  item.basic_property_id
                )
              "
            >
              <v-icon> mdi-heart-circle </v-icon>
            </v-btn>
          </template>
          <span class="caption">Added to favourite</span>
        </v-tooltip>
      </template>
      <template v-slot:item.image="{ item }">
        <div class="pa-2">
          <v-img
            :src="
              imgURL +
              '/' +
              item.user_id +
              '/' +
              item.basic_property_id +
              '/' +
              item.image_name
            "
            :alt="item.image_name"
            class="rounded"
            height="64px"
            width="64px"
          />
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Property from "../../api/Property";
export default {
  data() {
    return {
      snackbar: false,
      loading: false,
      text: "",
      snackbar_color: null,
      imgURL: "https://100milesrealtors.com/routes/storage/images",
      headers: [
        {
          text: "PROPERTY ID",
          align: "center",
          sortable: false,
          value: "basic_property_id",
        },
        {
          text: "Property For",
          value: "pfor",
          sortable: false,
          align: "center",
        },
        { text: "Image", value: "image", sortable: false, align: "center" },
        { text: "Property Type", value: "ptype", sortable: false },
        {
          text: "Property Category",
          value: "propertytype.property_name",
          sortable: false,
        },
        { text: "Price (₹)", value: "price" },
        { text: "Property Status", value: "propertystatus.property_status" },
        { text: "Actions", value: "actions", sortable: false, align: "center" },
      ],
    };
  },

  computed: {
    ...mapState({
      allAdminProperty: (state) => state.property.allAdminProperty,
    }),
  },
  mounted() {
    this.showAdminProperty();
  },
  methods: {
    ...mapActions(["showAdminProperty"]),
    upadatePropertyStatus(e, id) {
      this.loading = true;
      if (e == 1) {
        Property.upadatePropertyStatus(id, { property_status: 2 })
          .then((response) => {
            console.log(response.data);
            if (response.data.status_code == 201) {
              this.loading = false;
              this.text = response.data.message;
              this.snackbar = true;
              this.snackbar_color = "success";
              this.showAdminProperty();
            }
          })
          .catch((err) => {
            this.loading = false;
            this.text = err.response.data.message;
            this.snackbar = true;
            this.snackbar_color = "error";
          });
      } else if (e == 2) {
        Property.upadatePropertyStatus(id, { property_status: 1 })
          .then((response) => {
            if (response.data.status_code == 201) {
              this.loading = false;
              this.text = response.data.message;
              this.snackbar = true;
              this.snackbar_color = "success";
              this.showAdminProperty();
            }
          })
          .catch((err) => {
            this.loading = false;
            this.text = err.response.data.message;
            this.snackbar = true;
            this.snackbar_color = "error";
          });
      }
    },
    upadateFavouriteStatus(e, id) {
      this.loading = true;
      if (e == 0) {
        Property.upadateFavouriteStatus(id, { favourite_status: 1 })
          .then((response) => {
            console.log(response.data);
            if (response.data.status_code == 201) {
              this.loading = false;
              this.text = response.data.message;
              this.snackbar = true;
              this.snackbar_color = "success";
              this.showAdminProperty();
            }
          })
          .catch((err) => {
            this.loading = false;
            this.text = err.response.data.message;
            this.snackbar = true;
            this.snackbar_color = "error";
          });
      } else if (e == 1) {
        Property.upadateFavouriteStatus(id, { favourite_status: 0 })
          .then((response) => {
            if (response.data.status_code == 201) {
              this.loading = false;
              this.text = response.data.message;
              this.snackbar = true;
              this.snackbar_color = "success";
              this.showAdminProperty();
            }
          })
          .catch((err) => {
            this.loading = false;
            this.text = err.response.data.message;
            this.snackbar = true;
            this.snackbar_color = "error";
          });
      }
    },
  },
};
</script>
